import * as firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxFu2QZYrxQGxCDeKvU_Y9V10KhRnUyG4",
  authDomain: "hall-of-mirrors-brief.firebaseapp.com",
  databaseURL: "https://hall-of-mirrors-brief.firebaseio.com",
  projectId: "hall-of-mirrors-brief",
  storageBucket: "hall-of-mirrors-brief.appspot.com",
  messagingSenderId: "884108131930",
  appId: "1:884108131930:web:d594de96cc0c3952966263",
  measurementId: "G-7FM01DVX2M"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const provider = new firebase.auth.GoogleAuthProvider();

export default firebase;
