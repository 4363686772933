import React, { Component } from "react";
import styles from "./App.module.scss";
import { provider } from "../../firebase";
import firebase from "../../firebase";
import Image from "../../components/image/Image";

class App extends Component {
  state = { user: {} };

  signIn = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const token = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        this.setState({ user });
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.email;
        console.log(email);
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        console.log(credential);
        // ...
      });
  };

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ user: {} });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <section className={styles.container}>
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
          <Image data={this.state.user} />
        </section>
        <section>
          <button className={styles.help} onClick={this.signIn}>
            Sign in
          </button>
          {/* <button onClick={this.signOut}>Sign out</button> */}
        </section>
      </>
    );
  }
}

export default App;
