import React, { Component } from "react";
import styles from "./Image.module.scss";

class Image extends Component {
  state = { isRotated: true, randomNumber: 0, rotateStyle: {} };

  componentDidMount() {
    let randomNumber = Math.random();
    let rotateStyle = { transform: `rotate(${randomNumber}turn)` };
    this.setState({ randomNumber, rotateStyle });
  }

  handleClick = () => {
    this.setState({ isRotated: !this.state.isRotated });
  };
  render() {
    const rotateStyle = this.state.isRotated ? this.state.rotateStyle : {};
    return (
      <section className={styles.container}>
        <img
          onClick={this.handleClick}
          src={this.props.data.photoURL}
          alt={this.props.data.displayName}
          style={rotateStyle}
        />
      </section>
    );
  }
}

export default Image;
